import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

// import { Badge } from 'reactstrap';
import img1 from "../images/servicios2.jpg";
// import img2 from "../images/servicios3.jpg";
// import img3 from "../images/servicios4.jpg";
import img2 from "../images/leasing2.jpg";
import img3 from "../images/portal1.jpg";


const PortfolioPage = () => (
  <Layout>
    <SEO 
      title="Javier Suzuki | Design & Front-end Development Portfolio - Rock&Pop Blogs"
      keywords={[`javier`, `suzuki`, `web`, `ui`, `ux`, `design`, `front-end`, `development`, `wordpress`, `blog`]}
    />
    <div className="portfolio-header">
      <h1>Nacion Group</h1>
      <h5>Website, Web Applications & Marketing Campaigns</h5>
      <p>Design and front-end development of website and web applications for Nacion Group, working collaboratively with .NET developers, marketing directors and product owners.</p>
    </div>

    <div className="portfolio-content">
      <figure className="portfolio-sample">
        <figcaption className="figheading">Nacion Servicios Website</figcaption>
        <img src={img1} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        <figcaption className="figheading">Nacion Leasing Website</figcaption>
        <img src={img2} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        <figcaption className="figheading">Nacion Intranet</figcaption>
        <img src={img3} alt=""/>
      </figure>
      
    </div>

    <div className="portfolio-footer">
      {/* <a className="btn btn-primary" href="https://wushka.com">View Project</a> */}
    </div>

  </Layout>
)

export default PortfolioPage
